// Regex for splitting postal code + house number combinations (1111AB 11 format)
const postalRegex = /^([0-9]{4}[ ]?[a-z]{2})\s*([0-9]+[\s-]*)([0-9]*[a-z]*)$/i;

// Regex for postal code (1111AB or 1111 AB format)
const postalRegexNoNumber = /^([0-9]{4}[ ]?[a-z]{2})$/i;

// Regex for checking if number only contains numeric characters
const numberRegex = /^[0-9]+$/;

// Regex for checking if number extension only contains alphabetic characters
const numberExtensionRegex = /^[a-z0-9 -]+$/i;

// Regex for housenumber extention from raw-input (Staalstraat 42POEP to POEP). Group 1 = houseNumber only; Group 2 = Extension; Group 3 = City
const addressRegexExtension = /^[a-z'.\W]+([0-9]+)[ ]*([a-z]{1,2}|bis|bel|iii|vii|viii)(| [a-z]*)$/i;

// Validate if the Storage object is present
function hasStorage() {
    return !(Storage === 'undefined');
}

/**
 * Generate a string from location data
 * @param locationData
 */
export function generateLocationDisplay(locationData) {
    if (locationData == null) {
        return null;
    }

    let { number } = locationData;

    if (locationData.numberExtension != null && locationData.numberExtension.length > 0) {
        // Add '-' prefix when numberExtension starts with a number:
        if (numberExtensionRegex.test(locationData.numberExtension.charAt(0))) {
            number += '-';
        }
        number += locationData.numberExtension;
    } else if (locationData.extension != null && locationData.extension.length > 0) {
        number += ` ${locationData.extension}`;
    }

    if ((locationData.city == null || locationData.city.length === 0) && (locationData.street == null || !locationData.street.length)) {
        return `Adres: ${locationData.postCode}, nr. ${number}`;
    }

    let display = `${locationData.street} ${number}, ${locationData.postCode}`;

    if (locationData.city) {
        display = `${display} ${locationData.city}`;
    }

    return display;
}

export function isPostalCodeCombination(input) {
    return postalRegex.test(input.trim());
}

export function isPostalCodeCombinationWithoutNumber(input) {
    return postalRegexNoNumber.test(input.trim());
}

export function sanitizePostalCode(input) {
    if (!isPostalCodeCombinationWithoutNumber(input.trim())) {
        return null;
    }

    const regex = /^([0-9]{4})[ ]?([a-z]{2})$/i;
    const matches = regex.exec(input.toUpperCase());

    return `${matches[1]} ${matches[2]}`;
}

export function isNumber(input) {
    return numberRegex.test(input);
}

export function isNumberExtensionRegex(input) {
    return numberExtensionRegex.test(input);
}

export function hasStreet(input) {
    return input && input.length > 0;
}

export function hasStreetAndHouseNumber(input) {
    return /[a-z]+\s+[0-9]+/.test(input.trim());
}

export function hasExtension(input) {
    return addressRegexExtension.test(input.trim());
}

export function getExtension(input) {
    const result = addressRegexExtension.exec(input.trim());
    if (result && result.length >= 2) {
        return addressRegexExtension.exec(input.trim())[2];
    }
    return null;
}

export function generatePostalLocationData(input) {
    if (!postalRegex.test(input.trim())) {
        return null;
    }

    const matches = postalRegex.exec(input);

    const location = {
        postCode: matches[1],
        number: matches[2],
        numberExtension: matches[3],
        street: null,
        city: null,
    };
    if (location.numberExtension.length === 0) {
        location.numberExtension = null;
    }

    return location;
}

// Globally available compareLocationObject, this omits a lot of type checking code in the Views.
const compareLocationObject = {
    source: '',
    display: '',
    data: {
        street: '',
        number: '',
        numberExtension: '',
        postCode: '',
        city: '',
        address_found: false,
    },
    lookedUp: false,
};

export function getCompareLocation(key = 'compareLocation', context = window) {
    if (!hasStorage()) {
        return compareLocationObject;
    }

    const compareLocationData = context.localStorage.getItem(key);
    if (compareLocationData) {
        try {
            return JSON.parse(compareLocationData);
        } catch (e) {
            return compareLocationObject;
        }
    }

    return compareLocationObject;
}

export function setCompareLocation(value = {}, key = 'compareLocation', context = window) {
    if (!hasStorage()) {
        return false;
    }

    return context.localStorage.setItem(key, JSON.stringify(Object.assign(compareLocationObject, value)));
}

export function preLoadLocation(uri) {
    return fetch(`${uri}`);
}
